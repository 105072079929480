import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private authService: MsalService) { }

  canEdit(): boolean {
    const account = this.authService.instance.getAllAccounts()[0];
    if (!account) {
      return false;
    }
    const roles = account.idTokenClaims?.roles;
    if (!roles) {
      return false;
    }

    return roles.includes(environment.roles.admin);
  }
}
