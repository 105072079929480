import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { AccessForbiddenComponent } from './components/access-forbidden/access-forbidden.component';

const routes: Routes = [
  // If you only want to check if the user is logged in and don't care about the group
  // they belong to, add MsalGuard to canActivate.
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'forms/new'
  },
  {
    path: 'forms',
    loadChildren: () => import ('./reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'access-forbidden',
    component: AccessForbiddenComponent,
    canActivate: [MsalGuard]
  },
  { path: 'auth', component: MsalRedirectComponent }
];

const isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled' // Remove this line to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
