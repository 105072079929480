<header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom box-shadow mb-3">
        <div class="container-fluid">
            <a class="navbar-brand" style="color: #093479;" [routerLink]="['/']">
                <img src="assets/img/logo-lot.svg" width="100" style="margin-left: 0.5rem; margin-right: 10px"
                    class="d-inline-block" alt="Logo of LOT Polish Airlines">
                <strong class="d-none d-sm-inline">CHECK REPORT CABIN CREW</strong>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-md-0">
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/forms/new']" i18n>New form</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" [routerLink]="['/forms/local']" i18n>Local forms</a>
                    </li>
                    <li class="nav-item" *ngIf="canEdit">
                        <a class="nav-link" [routerLink]="['/forms']" i18n>All forms</a>
                    </li>
                </ul>
                <ul class="navbar-nav d-flex">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                            data-bs-toggle="dropdown" aria-expanded="false" [ngSwitch]="currLocale">
                            <img src="assets/img/gb.svg" width="24" height="18" alt="English" *ngSwitchCase="'en'">
                            <img src="assets/img/pl.svg" width="24" height="18" alt="Polish" *ngSwitchCase="'pl'">
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li>
                                <a class="dropdown-item" (click)="changeLocale('en')"><img src="assets/img/gb.svg" width="24" height="18" alt="English"></a>
                                <a class="dropdown-item" (click)="changeLocale('pl')"><img src="assets/img/pl.svg" width="24" height="18" alt="Polish"></a>
                            </li>
                        </ul>
                    </li>
                    <span class="navbar-text" *ngIf="loggedIn" i18n>You are logged in as: {{username}}</span>
                    <li class="nav-item" *ngIf="loggedIn">
                        <a class="nav-link text-dark" [routerLink]="[]" (click)="logout()" i18n>Sign out</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>