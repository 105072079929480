import { DBConfig } from "ngx-indexed-db";

export const dbConfig: DBConfig = {
    name: 'CheckReportCabinCrewDb',
    version: 1,
    objectStoresMeta: [
        {
            store: 'forms',
            storeConfig: {
                keyPath: 'id',
                autoIncrement: true
            },
            storeSchema: [
                { name: 'creationDate', keypath: 'creationDate', options: { unique: false } },
                { name: 'request', keypath: 'request', options: { unique: false } }
            ]
        }
    ]
}