import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { GroupService } from 'src/app/services/group.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  @Input() loggedIn: boolean | undefined;
  @Output() logoutRequested = new EventEmitter<boolean>();

  constructor(
    private groupService: GroupService,
    private authService: MsalService,
    @Inject(LOCALE_ID) public currLocale: string
  ) { }

  get canEdit(): boolean {
    return this.groupService.canEdit();
  }

  get username(): string {
    return this.authService.instance.getAllAccounts()[0].username;
  }

  logout(): void {
    this.logoutRequested.emit(true);
  }

  changeLocale(locale: string): void {
    if (locale === this.currLocale) {
      return;
    }
    let expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + 365 * 10 * 24 * 60 * 60 * 1000);
    document.cookie = `.User-Locale=${locale}; expires=${expireDate.toUTCString()}`;

    window.location.href = '/' + locale;
  }
}
