export const environment = {
  production: true,
  auth: {
    clientId: "#{authClientId}#",
    tenantId: "#{authTenantId}#"
  },
  apiUrl: window.location.origin + '/api/v1',
  apiScope: "#{apiScope}#",
  roles: {
    admin: "#{roleAdmin}#",
    user: "#{roleUser}#"
  }
};
